import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { RegistoCliente } from './components/RegistoCliente';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'

export default class App extends Component {
  static displayName = "Improxy: Registo de Clientes";

  render () {
    return (
      <Layout>
        <Route exact path='/' component={RegistoCliente} />
        <Route path='/registo-cliente' component={RegistoCliente} />
      </Layout>
    );
  }
}
