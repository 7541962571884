import React, { useState, useEffect } from 'react';
import LogotipoImproxy from '../images/improxy-white.png';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';

const useFormField = (initialValue: string = '') => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);
  return { value, onChange };
};

export function RegistoCliente() {
  const [submitting, setSubmitting] = useState(false);
  const [modalShowMsg, setModalShowMsg] = React.useState(false);
  const [companyType, setCompanyType] = useState('1');
  const [acceptance, setAcceptance] = useState(false);
  const [nameLabel, setNameLabel] = useState('Nome da empresa, organização ou entidade');
  const [vatNumber, setVatNumber] = useState('');

  const [reCaptcha, setReCaptcha] = useState(null);

  const nameField = useFormField();
  const addressField = useFormField();
  const zipCodeField = useFormField();
  const zipLocationField = useFormField();
  const contractNameField = useFormField();
  const contractEmailField = useFormField();
  const contractPhoneField = useFormField();
  const emailAccessAsAdministratorField = useFormField();

  const validateNIF = (nif) => {
    //if (pais.value === "6824")
    //{
    if (nif.length === 9) {
      let control;
      let added =
        nif[7] * 2 +
        nif[6] * 3 +
        nif[5] * 4 +
        nif[4] * 5 +
        nif[3] * 6 +
        nif[2] * 7 +
        nif[1] * 8 +
        nif[0] * 9;
      let mod = added % 11;
      if (mod === 0 || mod === 1) {
        control = 0;
      } else {
        control = 11 - mod;
      }
      if (nif[8] === control.toString()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    //}
    //return true;
  };

  const handleCompanyType = (event) => {
    setCompanyType(event.target.value);
    if (event.target.value === '1') {
      setNameLabel('Nome da empresa, organização ou entidade');
    } else {
      setNameLabel('Nome do condomínio');
    }
  };

  const handleAcceptance = (event) => {
    setSubmitting(false);
    setAcceptance(!acceptance);
  };

  function checkErrors(id, msg) {
    let _id = document.querySelector('#' + id);
    let _sid = document.querySelector('#s' + id);
    _id.setCustomValidity(msg);
    _sid.innerHTML = msg;
  }

  function checkEmail(id, value) {
    checkErrors(id, '');
    if (value.length > 3) {
      var re = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      if (!value.match(re)) {
        checkErrors(id, 'Formato inválido!');
      }
    } else if (value.length === 0) {
      checkErrors(id, 'Obrigatório');
    } else {
      checkErrors(id, 'Formato inválido!');
    }
  }

  const handleVatNumber = (event) => {
    checkErrors('vatNumber', '');
    if (event.target.value.length === 9) {
      if (!validateNIF(event.target.value)) {
        checkErrors('vatNumber', 'NIF inválido!');
      }
    }
    setVatNumber(event.target.value);
  };

  const blurVatNumber = (event) => {
    checkErrors('vatNumber', '');
    if (event.target.value.length === 9) {
      if (!validateNIF(event.target.value)) {
        checkErrors('vatNumber', 'NIF inválido!');
      }
    } else if (event.target.value.length === 0) {
      checkErrors('vatNumber', 'Obrigatório');
    } else {
      checkErrors('vatNumber', 'Formato inválido!');
    }
  };

  const blurZipCode = (event) => {
    checkErrors('zipCode', '');
    if (event.target.value.length === 8) {
      var re = new RegExp(/[\d*]{4}-[\d*]{3}/i);
      if (!event.target.value.match(re)) {
        checkErrors('zipCode', '<br />Código Postal inválido!');
      }
    } else if (event.target.value.length === 0) {
      checkErrors('zipCode', '<br />Obrigatório');
    } else {
      checkErrors('zipCode', '<br />Código Postal: Formato inválido!');
    }
  };

  const blurContractPhone = (event) => {
    checkErrors('contractPhone', '');
    if (event.target.value.length === 9) {
      var re = new RegExp(/9[\d*]{8}/i);
      if (!event.target.value.match(re)) {
        checkErrors(
          'contractPhone',
          'Formato inválido: Deverá começar por 9 e no total ter 9 dígitos.'
        );
      }
    } else if (event.target.value.length === 0) {
      checkErrors('contractPhone', 'Obrigatório');
    } else {
      checkErrors(
        'contractPhone',
        'Formato inválido: Deverá começar por 9 e no total ter 9 dígitos.'
      );
    }
  };

  const blurContractEmail = (event) => {
    checkEmail('contractEmail', event.target.value);
  };

  const blurEmailAccessAsAdministrator = (event) => {
    checkEmail('emailAccessAsAdministrator', event.target.value);
  };

  const onChangeReCaptcha = (value) => {
    document.querySelector('.reCaptcha').innerHTML = '';
    setReCaptcha(value);
  };

  async function submitData() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyType: companyType,
        name: nameField.value,
        address: addressField.value,
        zipCode: zipCodeField.value,
        zipLocation: zipLocationField.value,
        vatNumber: vatNumber,
        contractName: contractNameField.value,
        contractEmail: contractEmailField.value,
        contractPhone: contractPhoneField.value,
        emailAccessAsAdministrator: emailAccessAsAdministratorField.value,
        reCaptcha: reCaptcha,
      }),
    };
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/Heartbeat/ClientsPendingRegistration`,
      requestOptions
    );
    if (response.ok) {
      setModalShowMsg(true);
    } else {
      let validatorError = false;
      const data = await response.json();
      if (data.errors) {
        if (data.errors.NIF && data.errors.NIF.length > 0) {
          let vatNumber = document.querySelector('#vatNumber');
          vatNumber.setCustomValidity('NIF inválido!');
          validatorError = true;
        }
      }
      if (!validatorError) {
        showErro(
          'Ocorreu um erro. Por favor tente de novo.<br/>Se o problema persistir, entre em contacto com o nosso departamento comercial.'
        );
      }
    }
    setSubmitting(false);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let _noError = true;
    if (!validateNIF(vatNumber)) {
      let vatNumber = document.querySelector('#vatNumber');
      vatNumber.setCustomValidity('NIF inválido!');
      _noError = false;
    }

    if (reCaptcha === null || reCaptcha.length === 0) {
      document.querySelector('.reCaptcha').innerHTML = 'Obrigatório';
      _noError = false;
    }

    if (_noError) {
      setSubmitting(true);
      submitData();
    }
  };

  function showErro(msg) {
    let _status = document.querySelector('#lstatus');
    _status.className = 'red';
    _status.innerHTML = msg;
  }

  function showMsg(msg) {
    let _status = document.querySelector('#lstatus');
    _status.className = 'green';
    _status.innerHTML = msg;
  }

  function VerticallyCenteredModalMsg(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sucesso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Registo submetido</h4>
          <p>
            Por favor aguarde o nosso email com as credenciais para acesso que será enviado num
            prazo máximo de 1 dia útil.
            <br />
            Caso não receba a mensagem nesse período, verifique se a mesma não está na pasta de
            SPAM.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="wrapper ">
      <div id="dlogo">
        <img src={LogotipoImproxy} className="d-block" alt="Improxy" />
      </div>
      <h4 className="center margB1R">Bem-vindo ao registo de cliente Improxy.</h4>
      <h5 className="center margB1R">
        Por favor introduza a informação seguinte, necessária para a ativação.
      </h5>
      <p className="center grey">
        O seu registo será validado pela nossa equipa comercial e após validação irá receber um
        email de confirmação.
      </p>
      <p className="center underline">
        Este registo não implica nenhuma obrigação de contratação ou subscrição de serviços, nem a
        aceitação de qualquer tarifário ou preços.
      </p>
      <p className="center small">Todos os campos são de preenchimento obrigatório.</p>

      <form onSubmit={handleSubmit}>
        <fieldset>
          <h5>Informação geral</h5>

          <div className="margB">
            <label htmlFor="perfil">Seleccione perfil</label>
            <div className="input">
              <label>
                <input
                  type="radio"
                  id="empresa"
                  name="companyType"
                  value="1"
                  checked={companyType === '1'}
                  onChange={handleCompanyType}
                />{' '}
                <span htmlFor="empresa">
                  Empresa, organização ou entidade que administra um ou vários condomínios.
                </span>
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  id="administrador"
                  name="companyType"
                  value="2"
                  checked={companyType === '2'}
                  onChange={handleCompanyType}
                />{' '}
                <span htmlFor="administrador">
                  Administrador de condomínio em autoadministração.
                </span>
              </label>
            </div>
          </div>
          <div className="margB">
            <label htmlFor="vatNumber">NIF</label>
            <input
              type="text"
              id="vatNumber"
              maxLength="9"
              placeholder="NIF"
              pattern="[\d*]{9}"
              className="hinput wXS"
              required
              value={vatNumber}
              onBlur={blurVatNumber}
              onChange={handleVatNumber}
            />
            <span className="erro" id="svatNumber"></span>
          </div>
          <div className="margB">
            <label htmlFor="name">{nameLabel}</label>
            <input
              type="text"
              id="name"
              placeholder={nameLabel}
              className="hinput wXL"
              required
              {...nameField}
            />
          </div>
          <div className="margB">
            <label htmlFor="address">Morada</label>
            <textarea
              rows="3"
              cols="50"
              id="address"
              placeholder="Morada"
              className="wXL"
              required
              {...addressField}
            />
          </div>
          <div className="margB">
            <label htmlFor="zipCode">Código Postal e Localidade</label>
            <div className="input">
              <input
                type="text"
                id="zipCode"
                placeholder="Cód. Postal"
                maxLength="8"
                pattern="[\d*]{4}-[\d*]{3}"
                className="hinput wXS"
                required
                onBlur={blurZipCode}
                {...zipCodeField}
              />
              &nbsp;
              <input
                type="text"
                size="41"
                id="zipLocation"
                placeholder="Localidade"
                className="hinput wL"
                required
                {...zipLocationField}
              />
              <span className="erro" id="szipCode"></span>
            </div>
          </div>

          <h5>Responsável pelo contrato e pelos pagamentos</h5>

          <div className="margB">
            <label htmlFor="contractName">Nome</label>
            <input
              type="text"
              id="contractName"
              placeholder="Nome do responsável pelo contrato"
              className="hinput wXL"
              required
              {...contractNameField}
            />
          </div>
          <div className="margB">
            <label htmlFor="contractEmail">Email</label>
            <input
              type="email"
              id="contractEmail"
              placeholder="Endereço de email"
              className="hinput wXL"
              required
              onBlur={blurContractEmail}
              {...contractEmailField}
            />
            <span className="erro" id="scontractEmail"></span>
          </div>
          <div className="margB">
            <label htmlFor="contractPhone">Telemóvel</label>
            <input
              type="text"
              size="9"
              id="contractPhone"
              placeholder="Telemóvel"
              maxLength="9"
              pattern="9[\d*]{8}"
              className="hinput wXS"
              required
              onBlur={blurContractPhone}
              {...contractPhoneField}
            />
            <span className="erro" id="scontractPhone"></span>
          </div>

          <h5>Acesso à plataforma</h5>

          <div className="margB">
            <label htmlFor="emailAccessAsAdministrator">
              Email pretendido para efetuar a entrada na plataforma
              <br />
              <i>(Irá também receber neste endereço, o email de confirmação)</i>
            </label>
            <input
              type="email"
              id="emailAccessAsAdministrator"
              placeholder="Endereço de email"
              className="hinput wXL"
              required
              onBlur={blurEmailAccessAsAdministrator}
              {...emailAccessAsAdministratorField}
            />
            <span className="erro" id="semailAccessAsAdministrator"></span>
          </div>
          <div className="margB" id="dacceptance">
            <label className="black">
              Declaro ter lido, compreendido e aceite
              <br />
              os{' '}
              <a
                href="https://admin.improxy.com/App_Files/Termos%20e%20Condi%C3%A7%C3%B5es%20Gecond.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Termos e Condições de utilização
              </a>
              .
            </label>
            <input
              type="checkbox"
              id="aceitacao"
              name="aceitacao"
              checked={acceptance}
              onChange={handleAcceptance}
            />
          </div>
          <div>
            <label>
              <span className="required reCaptcha"></span>
            </label>
            <div id="drecaptcha">
              <ReCAPTCHA
                sitekey="6LcYYsAZAAAAAFYJqh7VQDWdtuEWlbJAWNffPyKS"
                onChange={onChangeReCaptcha}
              />
            </div>
          </div>
          <div>
            <label id="lstatus"></label>
            {acceptance ? (
              submitting ? (
                <Button type="submit" className="primary" disabled>
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                  &nbsp;&nbsp; A submeter...
                </Button>
              ) : (
                <Button type="submit" className="primary">
                  Submeter
                </Button>
              )
            ) : (
              <Button type="submit" className="primary" disabled>
                Submeter
              </Button>
            )}
          </div>
        </fieldset>
      </form>

      <VerticallyCenteredModalMsg show={modalShowMsg} onHide={() => setModalShowMsg(false)} />

      <div id="dfooter">
        <h6 className="center">
          ®IMPROXY, Lda – Todos os direitos reservados.
          <br />
          (+351) 223 749 100 &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp; comercial@improxy.com
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.improxy.com/"
            title="IMPROXY, Lda"
          >
            www.improxy.com
          </a>
        </h6>
      </div>
    </div>
  );
}
